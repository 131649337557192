// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Approval_outer__1WboG {
    position: absolute;
    top: 0px;
    min-width: 90vw;
    margin: 15px;
    margin-right: 35px;
  
    width: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .Approval_completeBlock__JFWsx {
    display: none;
  }
  .Approval_show__-AZCT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  table {
    width: 100%;
    border-collapse: collapse; /* Ensures the borders don't have space between them */
  }
  th,
  td {
    border: 1px solid black; /* Adds a border to each cell */
    padding: 8px;
    text-align: left; /* Aligns text to the left */
  }
  th {
    background-color: #f2f2f2; /* Light grey background for table headers */
  }
  
  .Approval_sign__ky\\+0b {
    display: flex;
    justify-content: center;
  }
  
  .Approval_submission__atMlo {
    display: flex;
    margin: 2px;
    margin-right: 5px;
  }
  .Approval_submission__atMlo div {
    margin-right: 7px;
  }
  .Approval_grinDetails__F\\+RTi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Approval/Approval.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,eAAe;IACf,YAAY;IACZ,kBAAkB;;IAElB,WAAW;IACX,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB,EAAE,sDAAsD;EACnF;EACA;;IAEE,uBAAuB,EAAE,+BAA+B;IACxD,YAAY;IACZ,gBAAgB,EAAE,4BAA4B;EAChD;EACA;IACE,yBAAyB,EAAE,4CAA4C;EACzE;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;EACd","sourcesContent":[".outer {\n    position: absolute;\n    top: 0px;\n    min-width: 90vw;\n    margin: 15px;\n    margin-right: 35px;\n  \n    width: auto;\n    overflow-x: hidden;\n    height: 100%;\n  }\n  .completeBlock {\n    display: none;\n  }\n  .show {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse; /* Ensures the borders don't have space between them */\n  }\n  th,\n  td {\n    border: 1px solid black; /* Adds a border to each cell */\n    padding: 8px;\n    text-align: left; /* Aligns text to the left */\n  }\n  th {\n    background-color: #f2f2f2; /* Light grey background for table headers */\n  }\n  \n  .sign {\n    display: flex;\n    justify-content: center;\n  }\n  \n  .submission {\n    display: flex;\n    margin: 2px;\n    margin-right: 5px;\n  }\n  .submission div {\n    margin-right: 7px;\n  }\n  .grinDetails {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer": `Approval_outer__1WboG`,
	"completeBlock": `Approval_completeBlock__JFWsx`,
	"show": `Approval_show__-AZCT`,
	"sign": `Approval_sign__ky+0b`,
	"submission": `Approval_submission__atMlo`,
	"grinDetails": `Approval_grinDetails__F+RTi`
};
export default ___CSS_LOADER_EXPORT___;
